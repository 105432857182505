<template>
  <div class="d-flex flex-column align-center">
    <ACheckbox
      v-model="adkData.acknowledgeScope"
      :rules="['required']"
      :disabled="!!adkData.completed"
      :styling="{
        label: message,
        dense: true,
        class: 'mt-3'
      }"
      @click="adkData.acknowledgeScope"
    />
    <!-- <a-button
      align="center"
      :styling="{
        label: 'Complete',
        color: 'blue',
        wrapperClass: 'mt-6',
        class: 'white--text',
        outlined: false,
        rounded: true,
        xLarge: true,
        depressed: false
      }"
      :loading="loading"
      :disabled="!adkData.acknowledgeScope || !!adkData.completed || isPreview"
      @click="$emit('click')"
    /> -->
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';

export default {
  components: {
    // AButton: () => import('@/components/atoms/AButton.vue'),
    ACheckbox: () => import('@/components/atoms/ACheckbox.vue')
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    adkType: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    model: {
      type: String,
      default: ''
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const programDoc = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkData = computed(() => {
      return programDoc.value?.data?.adks?.find(a => a.name === props.adkType);
    });

    const acknowledgeScope = computed({
      get: () => adkData.value.acknowledgeScope || false,
      set: newVal => {
        adkData.value.acknowledgeScope = newVal;
      }
    });

    return { programDoc, acknowledgeScope, adkData };
  }
};
</script>
